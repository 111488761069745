import React, {useState, useEffect} from 'react';
import {useSharedState} from '../store'
import {isNormalVariable} from '../services/functions'
import {serverFetchData} from "../services/serverFetch"
import {GroupByRecursive} from "../components/GroupByRecursive"
import CirkularProgress from '../components/CirkularProgress'
import ViewMatrix from '../components/ViewMatrix'

import {blue, red} from '@mui/material/colors';

const TABLE_NAME = 'tbl_precence';
const API_BASE_URL=process.env.REACT_APP_API_BASE_URL;

const styles = {
    default:{
        height:30, 
        marginTop:5, 
        textAlign:'center', 
        margin:'auto', 
        color:'yellow', 
        backgroundColor:'black', 
    }, 
    table:{
        margin:'auto', 
        backgroundColor:blue[800], 
        color:'silver', margin:'auto', 
        marginTop:2
    },
    circular:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    center:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        // width: '200px',
        // height: '200px',
    },
}

const _ViewBar = props => {
    const {depth, groupByArr, list} = props
    const groupByItem = groupByArr[depth]
    const style = groupByItem.style?groupByItem.style:styles.default
    const columns = groupByItem.columns // Colummns To show on the bar
    return(
        <div style={style}>
            {columns.map(col=>
                    <span col>
                        {list[0][col]}&nbsp;
                    </span>
            )}
        </div>
    )
}






const GROUP_BY_ARR=  [
    {
        groupByFunc:it=>it.scheduleName, 
        columns:['scheduleName'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[300], color:'black', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    },
    {
        groupByFunc:it=>it.city + it.siteId, 
        columns:['city', 'siteName'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[500], color:'gold', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    },
    {
        groupByFunc:it=>it.productId, 
        columns:['courseName', 'dayname', 'startTime'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[700], color:'silver', margin:'auto', marginTop:2},
        RenderView:_ViewBar, 
    },
    {
        RenderView:ViewMatrix, 
    }
];

const VIEW_FIELDS=['firstName', 'lastName', 'email', 'present'];
const VIEW_FIELDS_EXPAND=['firstName', 'lastName', 'email', 'present'];
const UPDATE_VIEW=['present'];
const UPDATE_FIELDS=['present'];

export default () => { 
    const [list, setList] = useState()
    const [sharedState, ] = useSharedState()
    const language = sharedState.language?sharedState.language:'EN'


    const handleReply1 = data => {
        if (data.status === 'OK' || data.status === 'true') {
            setList(data.result.filter(it=>isNormalVariable(it)))
        } else {
            alert(data.message)
        }
    }


    useEffect(()=>{
            const url1 = API_BASE_URL + '/getPresenceProducts?language=' + language
            serverFetchData(url1, handleReply1)
    },[language])

    return(
        <>
            {list?
                <GroupByRecursive 
                    depth={0} 
                    list={list} 
                    language={language}
                    groupByArr={GROUP_BY_ARR} 
                    tableName={TABLE_NAME}  // Needed by function ViewTable for update and delete
                    originalList={list}     // Needed by function ViewTable for update and delete
                    setOriginalList={setList} // Needed by function ViewTable for update and delete
                />
            :
                <CirkularProgress color={'whiteSmoke'} style={styles.circular} />
            }
        </>
    )
}

