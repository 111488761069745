import React, {useState, useEffect} from 'react';
import {serverFetchData} from "../services/serverFetch"
import CirkularProgress from '../components/CirkularProgress'
import {blue, red} from '@mui/material/colors';

const API_BASE_URL=process.env.REACT_APP_API_BASE_URL;

const styles = {
    table:{
        //display: 'block',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        backgroundColor:blue[800], 
        color:'silver', 
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:2

        
    },
    circular:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    message:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '200px',
        height: '200px',
    },
    center:{
        position: 'relative',
        width:'100%',
    },

}


// ViewMatrix
export default ({list, language}) => {
    const [matrix, setMatrix] = useState(undefined)
    const productId = list?list[0].productId:undefined
    const [message, setMessage] = useState()

    const handleReply = reply => {
        if (reply.status === 'OK') {
            const result = reply.result
            setMatrix(result?result[productId]?result[productId]:undefined:undefined)
        } else {
            setMessage('Nothing yet')
            setTimeout(()=>setMessage(undefined), 2000)
        }    
    }

    const compareFunction = (email1, email2) => {
        let ret 
        if ((ret =  Object.values(matrix[email1]).find(it=>it.firstName).firstName.localeCompare(
               Object.values(matrix[email2]).find(it=>it.firstName).firstName))!=0) {
            return ret    
        }
        if ((ret =  Object.values(matrix[email1]).find(it=>it.lastName).lastName.localeCompare(
            Object.values(matrix[email2]).find(it=>it.lastName).lastName))!=0) {
            return ret    
        }
        return 0
    }        

    useEffect(()=>{
        if (productId) {
            const url = API_BASE_URL + '/getPresenceHistoryMatrix?productId=' + productId + '&language=' + language 
            serverFetchData(url, handleReply)
        }    
    }, [list, productId])
    const firstEmail = matrix!==undefined?(Object.keys(matrix)[0]):undefined
    return (
        !firstEmail?
            <h3 className="title is-3" style={styles.message}>{message}</h3>
        :
            <div style={styles.center}>
                <table style={styles.table}>
                    <thead>
                    <th style={{paddingRight:3}}>name</th>
                    {Object.keys(matrix[firstEmail]).map(courseDate =>
                        <th style={{paddingRight:3}}>{courseDate}</th>
                    )}
                    </thead>
                <tbody>
                {Object.keys(matrix)?Object.keys(matrix).sort(compareFunction).map(email =>
                    <tr>
                        <td style={{paddingRight:3}}>
                            {Object.values(matrix[email]).find(it=>it.firstName).firstName}
                            &nbsp;
                            {Object.values(matrix[email]).find(it=>it.lastName).lastName}
                        </td>
                        {Object.values(matrix[email]).map(obj => <td>{obj.present?'X':'-'}</td>)}
                    </tr>
                ):null}
                </tbody>    
                </table>
            </div>
        )
}