import React, {useState, useEffect} from 'react';
import moment from 'moment'
import Button from '@mui/material/Button';
import TextArea from 'react-textarea-autosize';
import {useSharedState} from '../store'
import {isNormalVariable, isValidDate} from '../services/functions'
import {serverFetchData} from "../services/serverFetch"
import {GroupByRecursive} from "../components/GroupByRecursive"
import CirkularProgress from '../components/CirkularProgress'
import ViewTable from '../components/ViewTable'
import ViewMatrix from '../components/ViewMatrix'
import {replaceRow} from '../services/serverPost'

import {blue, red} from '@mui/material/colors';

const TABLE_NAME = 'tbl_presence';
const API_BASE_URL=process.env.REACT_APP_API_BASE_URL;

const styles = {
    default:{
        height:30, 
        marginTop:5, 
        textAlign:'center', 
        margin:'auto', 
        color:'yellow', 
        backgroundColor:'black', 
    }, 
    table:{
        margin:'auto', 
        backgroundColor:blue[800], 
        color:'silver', margin:'auto', 
        marginTop:2
    },
    circular:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },

}

const _ViewBar = props => {
    const {depth, groupByArr, list} = props
    const groupByItem = groupByArr[depth]
    const style = groupByItem.style?groupByItem.style:styles.default
    const columns = groupByItem.columns // Colummns To show on the bar
    return(
        <div style={style}>
            {columns.map(col=>
                    <span col>
                        {list[0][col]}&nbsp;
                    </span>
            )}
        </div>
    )
}



export const _ViewTable = props => {
    const {depth, columns, groupByArr, list} = props
    const [sharedState, ] = useSharedState()
    const [localList, setLocalList] = useState()
    const groupByItem = groupByArr[depth]
    const colsView = groupByItem.colsView?groupByItem.colsView:Object.keys(list[0])
    const defaultCourseDate = moment().format('YYYY-MM-DD')
    const [courseDate, setCourseDate] = useState(defaultCourseDate)
    const productId = list.length > 0?list[0].productId:undefined
    const [edit, setEdit] = useState(false)
    const language = sharedState.language

    const handleReplyFetch = reply => {
        if (reply.status === 'OK') {
            if (reply.result.length === 0) {
                setLocalList(list.filter(it=>it.courseDate === courseDate || !it.courseDate))  
            } else {
                if (reply.message) {
                    alert(reply.message)
                }
                        // If found present in the reply list 
                const newList = list.map(it=>{
                    // If the registrant is found in the result list with present set to 1, then change the present list to 1
                    if (reply.result.find(li=>(it.email === li.email && li.present == 1))) {
                        return {...it, courseDate,  present:1}
                    } else {
                        return {...it, courseDate}
                    }
                })
                setLocalList(newList)
            }
        } else {
            alert('ERROR:' + reply.message)
        }    
    }

    useEffect(()=>{
            const url = API_BASE_URL + '/getPresenceByCourseDay?productId=' + productId + '&courseDate=' + courseDate
            //alert(url)
            serverFetchData(url, handleReplyFetch)
    }, [productId, courseDate])

    const handleReplyReplace = (reply, row) => {
        if (reply.status === 'OK') {
            const newList = localList.map(it=>{
                if (it.email === row.email) {
                    return {...it, present:row.present?1:0}
                } else {
                    return it
                }
            })
            setLocalList(newList)
        } else {
            alert('ERROR')
        }
    }

    const handleChange = (e, row) => {
       const data = {...row, productId, courseDate, [e.target.name]:e.target.checked?1:0}
       // alert(JSON.stringify(data))
       replaceRow(TABLE_NAME, data, reply=>handleReplyReplace(reply, data)) 
       // alert(productId + ' ' + courseDate + ' ' + JSON.stringify(row)) 
    }

    return (
        <div className='columns is-centered'>
            <div className='column is-1'>
                <input type='date' value={courseDate} onChange={e=>setCourseDate(e.target.value)} />
            </div>
            <div className='column is-3'>
                <table style={styles.table}>
                    <tr>
                        {colsView.map((colName, ix) => 
                            <th key={ix} style={styles.th} onClick={()=>alert(colName)}>    
                                {colName}
                            </th>
                        )}    
                        <th style={styles.th}>Exists</th>
                    </tr>
                    <tbody style={styles.tbody}>
                        {localList?localList.map(row=> 
                            <tr>
                                {colsView.map(colName => <td>{row[colName]}</td>)}
                                <td>
                                    <input 
                                        type='checkbox' 
                                        name='present' 
                                        checked={row.present?1:0} 
                                        onChange={e=>handleChange(e, row)}
                                    />
                                </td>
                            </tr>
                        ):null}
                    </tbody>
                </table>
            </div>
            <div className='column is-8'>
                {productId?       
                    <ViewMatrix list={localList} language={language} />         
                :
                    <h1 className='title is-3'>Missing</h1>
                }    
            </div>
        </div>
    )
}

const GROUP_BY_ARR=  [
    {
        groupByFunc:it=>it.scheduleName, 
        columns:['scheduleName'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[300], color:'black', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    },
    {
        groupByFunc:it=>it.city + it.siteId,
        columns:['city', 'siteName'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[500], color:'silver', margin:'auto', marginTop:2},
        RenderView:_ViewBar, 
    },

    {
        groupByFunc:it=>it.productId, 
        columns:['courseName', 'dayname', 'startTime', 'productId'],
        style:{margin:'auto', width:'50vw', backgroundColor:blue[700], color:'silver', margin:'auto', marginTop:2},
        RenderView:_ViewBar, 
    },
    {
        colsView:['firstName', 'lastName'],
        colsEdit:['present'],
        RenderView:_ViewTable, 
    }
];

export default () => { 
    const [list, setList] = useState()
    const [sharedState, ] = useSharedState()
    const language = sharedState.language?sharedState.language:'EN'


    const handleReply1 = data => {
        if (data.status === 'OK' || data.status === 'true') {
            setList(data.result.filter(it=>isNormalVariable(it)))
        } else {
            alert(data.message)
        }
    }


    useEffect(()=>{
            const url1 = API_BASE_URL + '/getPupils?language=' + language
            serverFetchData(url1, handleReply1)
    },[language])

    return(
        <>
            {list?
                <GroupByRecursive 
                    depth={0} 
                    list={list} 
                    language={language}
                    groupByArr={GROUP_BY_ARR} 
                    tableName={TABLE_NAME}  // Needed by function ViewTable for update and delete
                    originalList={list}     // Needed by function ViewTable for update and delete
                    setOriginalList={setList} // Needed by function ViewTable for update and delete
                />
            :
                <CirkularProgress color={'whiteSmoke'} style={styles.circular} />
            }
        </>
    )
}


